<template>
    <!-- <div class="print-body">
        <table width="100%" border="1" cellspacing="0">
            <tr style="height: 30px">
                <td colspan="6">
                    <div class="print-title">
                        发货通知单
                    </div>
                </td>
            </tr>
            <tr style="height: 30px">
                <td style="text-align: right" colspan="6">
                    编号：<span style="color:red;">{{shippingForm.number}}</span>
                </td>
            </tr>
            <tr style="height: 30px">
                <td rowspan="11" width="5%">
                    发货信息申请表
                </td>
                <td style="text-align: left" width="90px">
                    客户名称：
                </td>
                <td style="text-align: left">
                    {{shippingForm.companyName}}
                </td>
                <td style="text-align: left" width="90px">
                    项目名称：
                </td>
                <td style="text-align: left; width: 30%" colspan="2">
                    {{shippingForm.projectName}}
                </td>
            </tr>
            <tr style="height: 30px">
                <td style="text-align: left" width="90px">
                    合同号：
                </td>
                <td style="text-align: left">
                    {{shippingForm.contractNum}}
                </td>
                <td style="text-align: left" width="90px">
                    销售人员：
                </td>
                <td style="text-align: left" colspan="2">
                    {{shippingForm.salesName}}
                </td>
            </tr>
            <tr style="height: 30px">
                <td style="text-align: left" width="90px">
                    产品名称：
                </td>
                <td style="text-align: left" colspan="2">
                    塔式起重机安全监控管理系统
                </td>
                <td style="text-align: left" width="15%">
                    订单数量：
                </td>
                <td style="text-align: left">
                    {{shippingForm.towerCount}}
                </td>
            </tr>
            <tr style="height: 30px">
                <td style="text-align: left" width="90px">
                    产品名称：
                </td>
                <td style="text-align: left" colspan="2">
                    施工升降机安全监控管理系统
                </td>
                <td style="text-align: left" width="17%">
                    订单数量：
                </td>
                <td style="text-align: left">
                    {{shippingForm.lifterCount}}
                </td>
            </tr>
            <tr style="height: 30px">
                <td style="text-align: left" width="90px">
                    联系电话：
                </td>
                <td style="text-align: left">
                    {{shippingForm.receivingUserTel}}
                </td>
                <td style="text-align: left" width="90px">
                    收货人：
                </td>
                <td style="text-align: left" colspan="2">
                    {{shippingForm.receivingUser}}
                </td>
            </tr>
            <tr style="height: 60px">
                <td style="text-align: left" width="15%">
                    送货地址：
                </td>
                <td style="text-align: left" colspan="4">
                    {{shippingForm.receivingAddress}}
                </td>
            </tr>
            <tr style="height: 60px">
                <td style="text-align: left" width="90px">
                    收费政策：
                </td>
                <td style="text-align: left" colspan="4">
                    {{shippingForm.chargePolicy}}
                </td>
            </tr>
            <tr style="height: 60px">
                <td style="text-align: left" width="90px">
                    销售备注：
                </td>
                <td style="text-align: left" colspan="4">
                    {{shippingForm.remark}}
                </td>
            </tr>
            <tr style="height: 60px">
                <td style="text-align: left" width="90px">
                    审批备注：
                </td>
                <td style="text-align: left" colspan="4">
                    {{shippingForm.remarkListStr}}
                </td>
            </tr>
            <tr style="height: 230px">
                <td style="text-align: left" width="17%">
                    设备信息清单：
                </td>
                <td style="text-align: left" colspan="4">
                    <div style="width: 100%;font-size: 10px">
                        <table width="100%">
                            <tr>
                                <td style="padding: 0" width="100px">规格型号：</td>
                                <td style="padding: 0" :key="i" v-for="(item,i) in tableData">
                                    {{item.sizeModel}}
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 0" width="100px">现场编码：</td>
                                <td style="padding: 0" :key="i" v-for="(item,i) in tableData">
                                    {{item.siteNum}}
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 0" width="100px">钢丝直径(mm)：</td>
                                <td style="padding: 0" :key="i" v-for="(item,i) in tableData">
                                    {{item.stringDiameter}}
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 0" width="100px">前臂长度(m)：</td>
                                <td style="padding: 0" :key="i" v-for="(item,i) in tableData">
                                    {{item.armLength}}
                                </td>
                            </tr>
							<tr>
                                <td style="padding: 0" width="100px">后臂长度(m)：</td>
                                <td style="padding: 0" :key="i" v-for="(item,i) in tableData">
                                    {{item.rearArmLength}}
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 0" width="100px">最大载重(T)：</td>
                                <td style="padding: 0" :key="i" v-for="(item,i) in tableData">
                                    {{item.maxLoad}}
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 0" width="100px">类型：</td>
                                <td style="padding: 0" :key="i" v-for="(item,i) in tableData">
                                    {{item.deviceType}}
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 0" width="100px">是否需要视频流量卡：</td>
                                <td style="padding: 0" :key="i" v-for="(item,i) in tableData">
                                    {{item.isNeedCard==1?'是':'否'}}
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 0" width="100px">是否需要现场调试多塔防碰撞：</td>
                                <td style="padding: 0" :key="i" v-for="(item,i) in tableData">
                                    {{item.isNeedModel==1?'是':'否'}}
                                </td>
                            </tr>
                        </table>
                    </div>
                </td>
            </tr>
            <tr style="height: 300px">
                <td style="text-align: left" width="90px">
                    功能清单：
                </td>
                <td style="text-align: left" colspan="4">
                    <div>{{shippingForm.towerFunList}}</div>
                    <div>{{shippingForm.lifterFunList}}</div>
                    <div>{{shippingForm.specialMap}}</div>
                </td>
            </tr>
        </table>
    </div> -->
    <div class="container">
         <div class="print-title">
            发货通知单
        </div>
        <div class="bianhao">
            <div style="display:flex">
                合同类型：
                <div v-if="shippingForm.afterSalesId" style="color:red">
                    <span>售后退换货</span>
                </div>
                <div v-else style="color:red">
                    <div v-if="shippingForm.applyType == 0">
                        <span>试用协议</span>
                    </div>
                    <div v-else>
                        <span v-if="shippingForm.contractType == 0">销售合同</span>
                        <span v-else-if="shippingForm.contractType == 1">技术服务合同</span>
                        <span v-else-if="shippingForm.contractType == 2">部件买卖合同</span>
                        <span v-else-if="shippingForm.contractType == 3">售后维修合同</span>
                    </div> 
                </div>
            </div>
            <div>
                编号：
                <span style="color:red;">{{shippingForm.number}}</span>
            </div>
        </div>
        <div class="main">
            <div class="left">发货信息申请表</div>
            <div class="right">
                <div class="row">
                    <div class="name">客户名称：</div>
                    <div class="value">{{shippingForm.companyName}}</div>
                    <div class="name">项目名称：</div>
                    <div class="value nobor">{{shippingForm.projectName}}</div>
                </div>
                <div class="row">
                    <div class="name">合同编号：</div>
                    <div class="value">{{shippingForm.contractNum}}</div>
                    <div class="name">销售人员：</div>
                    <div class="value nobor">{{shippingForm.salesName}}</div>
                </div>
                <div class="row">
                    <div class="name">产品名称：</div>
                    <div class="value">塔式起重机安全监控管理系统</div>
                    <div class="name">订单数量：</div>
                    <div class="value nobor">{{shippingForm.towerCount}}</div>
                </div>
                <div class="row">
                    <div class="name">产品名称：</div>
                    <div class="value">施工升降机安全监控管理系统</div>
                    <div class="name">订单数量：</div>
                    <div class="value nobor">{{shippingForm.lifterCount}}</div>
                </div>
                <div class="row">
                    <div class="name">联系电话：</div>
                    <div class="value">{{shippingForm.receivingUserTel}}</div>
                    <div class="name">收货人员：</div>
                    <div class="value nobor">{{shippingForm.receivingUser}}</div>
                </div>
                <div class="row">
                    <div class="name">送货地址：</div>
                    <div class="lang-value">{{shippingForm.receivingAddress}}</div>
                </div>
                <div class="row">
                    <div class="name">收费政策：</div>
                    <div class="lang-value">{{shippingForm.chargePolicy}}</div>
                </div>
                <div class="row">
                    <div class="name">销售备注：</div>
                    <div class="lang-value">{{shippingForm.remark}}</div>
                </div>
                <div class="row">
                    <div class="name">审批备注：</div>
                    <div class="lang-value">{{shippingForm.remarkListStr}}</div>
                </div>
                <div class="row">
                    <div class="name">信息清单：</div>
                    <table>
                        <tr>
                            <td class="table-name">规格型号</td>
                            <td v-for="(item,i) in tableData" :key="i">{{item.sizeModel}}</td>
                        </tr>
                         <tr>
                            <td class="table-name">现场编码</td>
                            <td v-for="(item,i) in tableData" :key="i">{{item.siteNum}}</td>
                        </tr>
                         <tr>
                            <td class="table-name">钢丝直径(mm)</td>
                            <td v-for="(item,i) in tableData" :key="i">{{item.stringDiameter}}</td>
                        </tr>
                         <tr>
                            <td class="table-name">前臂长度(m)</td>
                            <td v-for="(item,i) in tableData" :key="i">{{item.armLength}}</td>
                        </tr>
						<tr>
                            <td class="table-name">后臂长度(m)</td>
                            <td v-for="(item,i) in tableData" :key="i">{{item.rearArmLength}}</td>
                        </tr>
                         <tr>
                            <td class="table-name">最大载重(T)</td>
                            <td v-for="(item,i) in tableData" :key="i">{{item.maxLoad}}</td>
                        </tr>
                         <tr>
                            <td class="table-name">类型</td>
                            <td v-for="(item,i) in tableData" :key="i">{{item.deviceType}}</td>
                        </tr>
                         <tr>
                            <td class="table-name">是否需要视频流量卡</td>
                            <td v-for="(item,i) in tableData" :key="i">{{item.isNeedCard==1?'是':'否'}}</td>
                        </tr>
                         <tr>
                            <td class="table-name">是否需要现场调试多塔防碰撞</td>
                            <td v-for="(item,i) in tableData" :key="i">{{item.isNeedModel==1?'是':'否'}}</td>
                        </tr>
                    </table>
                </div>
                <div class="row">
                    <div class="name">功能清单：</div>
                    <div class="lang-value;">
                        <div class="str">{{shippingForm.towerFunList}}</div>
                        <div class="str">{{shippingForm.lifterFunList}}</div>
                        <div class="str">{{shippingForm.specialMap}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    var qs = require('qs');
    export default {
        data() {
            return {
                tableData: [],
                remarkList: [],
                shippingForm: {
                    number: '',
                    companyName: '',
                    projectName: '',
                    contractNum: '',
                    salesName: '',
                    towerCount: '',
                    lifterCount: '',
                    receivingUser: '',
                    receivingAddress: '',
                    receivingUserTel: '',
                    deviceListStr: '',
                    towerFunList: '',
                    lifterFunList: '',
                    chargePolicy: '',
                    remark: '',
                    remarkListStr: '',
                    specialMap: '',
                }
            }
        },
        mounted() {
            window.print()
        },
        created() {
            var dataObj = qs.parse(localStorage.getItem("shippingData"))
            this.shippingForm = {
                number: dataObj.sendNoticeNum,
                companyName: dataObj.companyName,
                projectName: dataObj.projectName,
                contractNum: dataObj.contractNum,
                salesName: dataObj.userName,
                towerCount: dataObj.towerCount,
                lifterCount: dataObj.lifterCount,
                receivingUser: dataObj.receivingUser,
                receivingAddress: dataObj.receivingAddress,
                receivingUserTel: dataObj.receivingUserTel,
                deviceListStr: dataObj.deviceListStr,
                chargePolicy: dataObj.chargePolicy,
                remark: dataObj.remark,
                remarkListStr: '',
                specialMap: dataObj.specialMap,
                contractType:dataObj.contractType,
                afterSalesId:dataObj.afterSalesId,
                applyType:dataObj.applyType,
            }
            this.tableData = this.shippingForm.deviceListStr;
            var towerItemsStr = dataObj.towerFunList;
            if (towerItemsStr) {
                var towerStr = '塔机：'
                for (var i in towerItemsStr) {
                    towerStr = towerStr + i + ':' + towerItemsStr[i] + ' ; '
                }
                this.shippingForm.towerFunList = towerStr;
                if (towerStr.match('0:0')) {
                    towerStr = ''
                }
            }
            var lifterItemsStr = dataObj.lifterFunList;
            if (lifterItemsStr) {
                var lifterStr = '升降机：'
                for (var i in lifterItemsStr) {
                    lifterStr = lifterStr + i + ':' + lifterItemsStr[i] + ' ; '
                }
                if (lifterStr.match('0:0')) {
                    lifterStr = ''
                }
                this.shippingForm.lifterFunList = lifterStr;
            }
            this.remarkList = dataObj.remarkList;
            var remarkListStr = '';
            for (var i = 0; i < this.remarkList.length; i++) {
                if(this.remarkList[i].auditRemark == '同意'){
                    continue;
                }else {
                    remarkListStr = remarkListStr + this.remarkList[i].auditRemark + '；'
                }
            }
            if ( remarkListStr == ''){
                remarkListStr = '无'
            }
            this.shippingForm.remarkListStr = remarkListStr;
            var specialStr = '';
            for (const key in this.shippingForm.specialMap) {
                specialStr = specialStr + key + ':' + this.shippingForm.specialMap[key] + '；'
            }
            this.shippingForm.specialMap = specialStr
        }

    }
</script>

<style lang="scss" scoped>
.container{
    height: 100%;
    width: 950px;
    margin: 0 auto;
}
.print-title {
    border: 1px solid #000;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    font-weight: bold;
}
.bianhao{
    height: 40px;
    line-height: 40px;
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    box-sizing: border-box;
    padding-right: 1%;
    border: 1px solid #000;
    border-top: none;
}
.main{
    display: flex;
}
.left{
    display: flex;
    align-items: center;
    text-align: center;
    width: 30px;
    border: 1px solid #000;
    border-top: none;
}
.right{
    flex: 1;
    border: 1px solid #000;
    border-left: none;
    border-top: none;
    border-bottom: none;
}
.row{
    border-bottom: 1px solid #000;
    min-height: 32px;
    display: flex;
}
.name{
    width: 85px;
    display: flex;
    align-items: center;
    padding-left: 5px;
}
.value{
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 5px;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
}
.nobor{
    border-right:none
}
.lang-value{
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 5px;
    border-left: 1px solid #000;
}
table{
    width: 825px;
    table-layout: fixed;
    border-collapse: collapse;
    font-size: 14px;
    tr{
        td{
            word-wrap:break-word;
            border: 1px solid #eee;
            text-align: center;
        }
        .table-name{
            width: 75px;
        }
    }
}
.str{
    padding: 5px;
}
</style>